import React, { useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Layout from '../components/Layout';
import ContactImage from '../img/images/contact.jpg';
import { navigate } from 'gatsby-link';

import { HeroSection, UnderLine, UnderlineHeader, Button } from '../components/global-style';


const accessKey = '47de0f37-9737-419f-ad3c-aaec4b684655';
const Contact = () => {
  const [contact, setContact] = useState({
    name: '',
    firstname: '',
    email: '',
    phone: '',
    company: '',
    subject: 'New contact form submission',
    honeypot: '', // if any value received in this field, form submission will be ignored.
    message: '',
    replyTo: '@', // this will set replyTo of email to email address entered in the form
    accessKey: accessKey, // get your access key from https://www.staticforms.xyz
  });

  const [response, setResponse] = useState({
    type: '',
    message: '',
  });

  const handleChange = e => setContact({ ...contact, [e.target.name]: e.target.value });

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const res = await fetch('https://api.staticforms.xyz/submit', {
        method: 'POST',
        body: JSON.stringify(contact),
        headers: { 'Content-Type': 'application/json' },
      });

      const json = await res.json();

      if (json.success) {
        setResponse({
          type: 'success',
          message: 'Thank you for reaching out to us.',
        });
        navigate('/thanks')
      } else {
        setResponse({
          type: 'error',
          message: json.message,
        });
      }
    } catch (e) {
      console.log('An error occurred', e);
      setResponse({
        type: 'error',
        message: 'An error occured while submitting the form',
      });
    }
  };
  return (
    <Layout>
      <Helmet>
        <html lang="fr" />
        <title>Contact-TBWA \ DJAZ</title>
        <meta
          name="description"
          content="Vous souhaitez vous différencier de vos concurrents ? Propulser votre marque ? Accroitre votre crédibilité auprès de vos audiences ? Contactez-nous !"
        />
            <meta
              property="og:image"
              content={ContactImage}
              data-react-helmet="true"
            />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content="TBWA\ DJAZ" />
        <meta property="og:url" content="/http://tbwadjaz.com" />
      </Helmet>
      <HeroSection bgImage={ContactImage} height="60vh">
        <ContactContainer>
          <h1>Contact</h1>
          <p>
            Vous souhaitez vous différencier de vos concurrents ? Propulser votre marque ? Accroitre votre crédibilité
            auprès de vos audiences ? Nous mettons à votre service 15 années d'expertise, une méthodologie unique et une
            capacité de gestion de projets intégrés par nos équipes pluridiciplinaires.
          </p>
          <UnderLine />
        </ContactContainer>
      </HeroSection>
      <ContactUsSection>
        <UnderlineHeader>Comment pouvons-nous vous aider ?</UnderlineHeader>
        <FormContainer>
          <div className={response.type === 'success' ? 'tile box notification is-primary' : 'is-hidden'}>
            <p>{response.message}</p>
          </div>
          <div className={response.type === 'error' ? 'tile box notification is-danger' : 'is-hidden'}>
            <p>{response.message}</p>
          </div>
          <div className={response.message !== '' ? 'is-hidden' : 'columns'}>
            <form action="https://api.staticforms.xyz/submit" method="post" onSubmit={handleSubmit}>
              <input
                className="input"
                type="text"
                placeholder="Votre nom"
                name="name"
                onChange={handleChange}
                required
              />

              <input
                className="input"
                type="text"
                placeholder="Votre prénom"
                name="firstname"
                onChange={handleChange}
                required
              />

              <input
                className="input"
                type="number"
                placeholder="Votre numéro de téléphone"
                name="phone"
                onChange={handleChange}
                required
              />

              <input
                className="input"
                type="email"
                placeholder="Votre email"
                name="email"
                onChange={handleChange}
                required
              />

              <div className="control">
                <input type="text" name="honeypot" style={{ display: 'none' }} onChange={handleChange} />
                <input type="hidden" name="subject" onChange={handleChange} />
              </div>

              <textarea
                className="textarea"
                placeholder="Votre message"
                name="message"
                onChange={handleChange}
                required
              />

              <Button type="submit">
                Envoyer
              </Button>
            </form>
          </div>
          <div className="column" />
        </FormContainer>
      </ContactUsSection>
    </Layout>
  );
};
export default Contact;

const ContactContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;
const ContactUsSection = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;

  .contact-info {
    display: flex;
    img {
      width: 100px;
    }
    .manager {
      width: 500px;
    }

    h6 {
      margin: 5px 0;
    }

    p {
      padding: 8px;
    }
  }
  @media (max-width: 800px) {
    .contact-info {
      flex-wrap: wrap;
      img {
        width: 100px;
      }
    }
  }
`;

const FormContainer = styled.div`
  padding: 20px 0;
  form {
    display: flex;
    flex-direction: column;
    textarea:focus,
    input:focus {
      outline: none;
    }
    input,
    textarea {
      border: unset;
      transition: ease-in-out, width 0.35s ease-in-out;
      padding: 20px 10px;
      background: var(--tbwa-gray);
      margin: 10px 0;
    }
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
`;
